.Featured {
  &__image {
    width: 100%;
  }

  &__icons {
    margin-top: rem-calc(20);
    text-align: center;
  }

  &__icon {
    width: 26px;
    height: 20px;
    display: inline-block;
    background-size: 100%;

    &--csfd {
      margin: 0 rem-calc(10);
      background-image: url('../img/csfd-bw.png');
      transition: background-image .8s;

      &:hover {
        background-image: url('../img/csfd.png');
        transition: all .8s;
      }
    }
  }
}