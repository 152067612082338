.Top-menu {
  margin: rem-calc(10) auto rem-calc(30) auto;
  text-align: center;

  font-family: "Roboto", sans-serif;

  font-size: 1.2em;

  a {
    text-decoration: none;
    color: $body-font-color;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: inline-block;
    letter-spacing: 2px;
  }

  &__item {
    display: inline-block;
    position: relative;
    border-bottom: 2px solid white;

    font-weight: 900;

    padding: 0 rem-calc(3) rem-calc(2) rem-calc(3);
    margin: 0 rem-calc(10);

    &:hover {
      border-bottom: 2px solid $body-font-color;;
      transition: all .8s;
    }

  }
}
