@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin-ext');

html {
  height:100%;
}

body {
  // background-color: rgb(240,240,240);
  font-family: "Open Sans", sans-serif;
  min-height: 100%;
  position: relative;
}

.Footer {
  /*
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  */
  height: rem-calc(100);

  background-image: url('../img/background-sepia-bw.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

h1, h2, h3 {
  font-family: "Roboto", sans-serif;
}

h2 {
  &.section {
    position: relative;
    display: inline-block;
    &:before {
      content: "";
      position: absolute;
      width: 60px;
      height: 60px;
      left: -40px;
      top: -20px;
      background-image: url('../img/projector.png');
      background-size: 100%;
      opacity: 0.2;
    }
  }
}

$row-margin: rem-calc(80);

.row {
  margin-top: $row-margin;
  margin-bottom: $row-margin;

  &--flat {
    margin-top: 0;
    margin-bottom: 0;
  }

  &--first {
    margin-top: $row-margin / 4;
    margin-bottom: $row-margin;
  }
}


#map_canvas {
  height: 300px;
  margin-top: $row-margin;
  margin-bottom: $row-margin;
}

.semantic {
  &-info {
    color: $dark-gray;
  }
}

.Program {

  padding: 1em;
  box-shadow: 1px 1px 5px 0.8px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;


  h3 {
    font-size: 1em;
    font-weight: 800;
    letter-spacing: 2px;
    margin-bottom: 1.5em;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 1em;
    color: $dark-gray;
  }

  .title {
    font-weight: 600;
    color: $body-font-color;
  }

  &::after {
    content: "";
    display: block;
    background-image: url('../img/projector.png');
    background-size: 100%;
    width: 100px;
    height: 100px;
    opacity: 0.3;
    rotation: 30deg;
    position: absolute;
    bottom: -30px;
    right: -20px;
  }
}

.people {

  img {
    filter: grayscale(100%);
    transition: all .8s;

    &:hover {
      filter: grayscale(0%);
      transition: all .8s;
    }
  }

}